<template>
  <div class="tLiBox">
    <div class="usxBox">
      <div class="usInfo">
        <i class="el-icon-user-solid FsB1"></i>
        <span class="uname FsB1">{{ props.iobj.name }}</span>
      </div>
      <div class="rtes">
        <template v-if="props.iobj.xgurl !== null">
          <a class="disInB Mr12" target="_blank" :href="props.iobj.xgurl">
            相关URL
          </a>
        </template>
        {{ props.iobj.rtes }}
        {{ props.iobj.cycle }}
      </div>
    </div>
    <div class="mtes FsH7" v-html="props.iobj.mtext"></div>
    <div v-if="props.iobj.file" class="fileSpan">
      <span v-for="(ita, itx) in zfzar(props.iobj.file, ',')" :key="itx">
        <i class="el-icon-paperclip"></i>
        <a :href="fileurl + ita" target="_blank">{{ zfzar(ita, '/')[1] }}</a>
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
const props = defineProps({
  iobj: {
    type: Object,
    required: {}
  }
})
const fileurl = window.g.baseURL

// 转换
const zfzar = (artx, vs) => {
  console.log('转换--', artx.split(vs))
  return artx.split(vs)
}
</script>

<style lang="scss" scoped>
.tLiBox {
  padding: 16px 0;
  border-bottom: dotted 1px #ddd;
  .mtes {
    margin-top: 10px;
    line-height: 200%;
    font-size: 14px;
  }
}
.fileSpan {
  span {
    width: 300px;
    display: inline-block;
    padding: 6px 0;
    a {
      color: #777;
      font-size: 13px;
    }

    a:hover {
      color: #000;
    }
  }
}
</style>
