<template>
  <div class="tLiBox">
    <div class="usxBox">
      <div class="usInfo">
        <i class="el-icon-user-solid FsB1"></i>
        <span class="uname FsB1">{{ props.iobj.sqName }}</span>
      </div>
      <div class="rtes ">
        <span class="F12">申请时间：{{ props.iobj.sqTime }}</span>
      </div>
    </div>
    <div class="mtes">
      <span>{{ stit }}</span>
      <span class=" FsH7" v-html="props.iobj.content"></span>
    </div>
    <!-- 需要雇主回复 -->
    <template v-if="xygzhf === true">
      <div class="Mt16 Pb16 TxC" v-if="gzhf === false">
        <div class="gzhf">
          <span class="lab">{{ sName }}意见：</span>
          <span class="jtg">
            <el-radio-group v-model="radio">
              <el-radio size="small" :label="1">同意</el-radio>
              <el-radio size="small" :label="2">拒绝</el-radio>
            </el-radio-group>
          </span>
          <div class="hfx" v-if="radio === 2">
            <el-input v-model="reply" size="small" placeholder="拒绝原因" />
          </div>
          <span class="btnG Ml12">
            <el-button type="primary" size="small" @click="gzHuifu">
              确定
            </el-button>
          </span>
        </div>
      </div>
      <div v-else class="mtes">
        <span class="FsH2">{{ sName }}意见：</span>
        <template v-if="radio === 1">
          <span class="FsB1">同意</span> &nbsp;&nbsp;
          <span class=" FsH7">{{ reply }}</span>
        </template>
        <template v-else>
          <span class="FsHs">拒绝</span> &nbsp;&nbsp;
          <span class=" FsHs">{{ reply }}</span>
        </template>
      </div>
    </template>
    <!-- 雇主已回复 -->
    <template v-else>
      <div v-if="props.iobj.state === 0" class="mtes TxC">
        <p class="FsHs">{{ sName }}未回复-</p>
      </div>
      <div v-else class="mtes">
        <span class="FsH2">{{ sName }}意见：</span>
        <template v-if="props.iobj.state === 1">
          <span class="FsB1">同意</span> &nbsp;&nbsp;
          <span class=" FsH7">{{ props.iobj.reply }}</span>
        </template>
        <template v-else>
          <span class="FsHs">拒绝</span> &nbsp;&nbsp;
          <span class=" FsHs">{{ props.iobj.reply }}</span>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from 'vue'
import { sign } from '@/utils/storage'
import { getGzToapplyforHuifu } from '@/api/task'
import { ElMessage } from 'element-plus'
const stit = ref('申请撤销原因：')
const sName = ref('雇主')
const radio = ref(1)
const reply = ref('无意见')
const xygzhf = ref(false) // 需要雇主回复
const gzhf = ref(false) // 雇主是否回复
const props = defineProps({
  iobj: {
    type: Object,
    required: {}
  }
})

const gzHuifu = async () => {
  console.log('props-.sqId--', props.iobj.sqId)
  if (reply.value === '') {
    ElMessage.error('请输入拒绝原因')
    return false
  }
  var parmas = {
    tID: props.iobj.tID, // 主任务ID
    tsID: props.iobj.tsID, // 子任务ID
    sqId: props.iobj.sqId, // 申请ID
    sqUsID: props.iobj.sqUsID, // 申请用户ID
    state: radio.value,
    type: props.iobj.type,
    reply: reply.value,
    sjs: Math.ceil(Math.random() * 10)
  }

  var res = await getGzToapplyforHuifu(sign(parmas))
  console.log(res)
  if (res === 'ok') {
    gzhf.value = true
  }
}
watch(radio, (newValue, oldValue) => {
  console.log('radio===', newValue, oldValue)
  if (newValue === 1) {
    reply.value = '无意见'
  } else {
    reply.value = ''
  }
})

watch(
  () => props.iobj,
  (newVal, oldVal) => {
    console.log('iobj--newVal, oldVal', newVal, oldVal)
    if (newVal.state === 0 && newVal.ss === 0 && newVal.gz === true) {
      xygzhf.value = true
    }
    if (newVal.ss === 1) {
      stit.value = '向平台申诉内容：'
      sName.value = '平台'
    } else {
      stit.value = ''
      sName.value = '雇主'
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.gzhf {
  display: flex;
  span {
    display: inline-block;
    line-height: 32px;
  }
  span.lab {
    width: 80px;
    line-height: 32px;
  }
  .jtg {
    width: 160px;
    .el-radio {
      height: 32px;
    }
  }
}
.tLiBox {
  padding: 16px 0;
  border-bottom: dotted 1px #ddd;
  .mtes {
    margin-top: 10px;
    line-height: 200%;
    font-size: 14px;
  }
}
.fileSpan {
  span {
    width: 300px;
    display: inline-block;
    padding: 6px 0;
    a {
      color: #777;
      font-size: 13px;
    }

    a:hover {
      color: #000;
    }
  }
}
</style>
